<template>
    <section class="bg p-3">
        <div id="layoutAuthentication">
            <div id="layoutAuthentication_content">
                <main>
                    <div class="container-fluid">
                        <div class="row justify-content-center">
                            <div class="col-lg-5">
                                <div class="card shadow-lg border-0 rounded-lg mt-5">
                                    <div class="card-header">
                                        <h3 class="text-center font-weight-light my-2 fw-bold">{{ this.$route.params.hospitalName }}</h3>
                                        <h3 class="text-center font-weight-light my-2 fw-bold">纖維肌痛症預約掛號</h3>
                                        <h4 class="text-center font-weight-light my-2 fw-bold">（非醫院正式門診掛號）</h4>
                                        <h6 id="errorMessage" style="color: red"></h6>
                                    </div>
                                    <div class="card-body text-start fw-bold">
                                        <p>此掛號功能為提供醫師門診時快速找到患者額外資料進行分析與診斷，門診掛號請循各醫院之掛號規則，謝謝！</p>
                                        <p>當您選擇掛號指定醫師時，將允許該醫師查看您在此網站的問卷紀錄與服藥紀錄，以利醫師了解您的病史進行了解。</p>
                                        <hr />
										<div v-if=" this.dataList?.length==0 ">
											該醫院尚未有服務的醫師
										</div>
                                        <div v-for="(item, key) in dataList" :key="key">
                                            <div class="form-check mx-3 my-2 fs-6 text-start">
                                                <input class="form-check-input" type="radio" name="selectedDoctor" v-model="selectedMappingId" :value="item.doctorId" :id="key" />
                                                <!-- <label class="form-check-label" :for="key">{{item.hospitalName}} {{item.division}} {{item.name}}</label> -->
                                                <label class="form-check-label" style="width: 250px" :for="key">
                                                    <!-- <div class="row">
                                                        <div class="col">{{ item.hospitalName }}</div>
                                                        <div class="col">{{ item.division }}</div>
                                                        <div class="col">{{ item.name }}</div>
                                                    </div> -->
                                                    <div class="row">
                                                        <div class="col">{{ item.hospitalName }} - {{ item.division }}</div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col">{{ item.name }}</div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer text-center py-3">
                                        <button class="btn btn-purple" @click="sendAppointment()">確認掛號</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </section>
</template>

<script>
// @ is an alias to /src
import qs from "qs";
import { ref, reactive, getCurrentInstance } from "vue";

export default {
    name: "Appointment",
    components: {},
    setup(props, ctx) {
        const currentInstance = getCurrentInstance();
        const { $axios, $http, $message, $route } = currentInstance.appContext.config.globalProperties;
    },
    data() {
        return {
            dataList: null,
            selectedMappingId: null,
        };
    },
    methods: {
        getHospitalAndDoctor() {
            this.$axios({
                url: `/hospital/${this.$route.params.hospitalName}`,
                method: "GET",
            })
                .then((res) => {
                    // console.log(res.data);
                    if (res.data.code === 200) {
                        // console.log(res.data);
                        this.dataList = res.data.data;
						// console.log(this.dataList);
                    } else if (res.data.code === 400) {
                        // alert(`${res.data.msg}!!`);
                    }
                })
                .catch((error) => {
                    console.log("something error when get hospital and doctor search!!");
                    console.log(error);
                });
        },
        left(str, len, c) {
            c = c || " ";
            str = str + "";
            if (str.length >= len) {
                // console.log('length>=');
                return str;
            }
            // console.log('source '+str);
            // console.log(Array(len - str.length + 1).join(c) + str)
            return Array(len - str.length + 1).join(c) + str;
        },
        sendAppointment() {
            if (this.selectedMappingId == null) {
                alert("未選擇醫師！");
                return;
            }
            this.$axios({
                url: `/appointment/${this.selectedMappingId}`,
                method: "POST",
            })
                .then((res) => {
                    // console.log(res.data);
                    if (res.data.code === 200) {
                        console.log(res.data);
                        if (res.data.msg == "success") {
                            alert("您的掛號已成功，提醒您還是需要在醫院進行正式門診掛號哦！");
                        }
                    } else if (res.data.code === 400) {
                        alert(`${res.data.msg}`);
                    }
                })
                .catch((error) => {
                    console.log("something error when send appointment!!");
                    console.log(error);
                });
        },
    },
    mounted() {
        this.getHospitalAndDoctor();
    },
};
</script>
