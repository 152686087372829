<template>
    <div id="layoutSidenav_nav">
        <nav class="sb-sidenav accordion sb-sidenav-custom" id="sidenavAccordion">
            <div class="sb-sidenav-menu">
                <div class="nav">
                    <div class="sb-sidenav-menu-heading">
                        <span>
                            <button @click="changeColor('blue')" id="blue" class="color mx-1"></button>
                            <button @click="changeColor('pink')" id="pink" class="color mx-1"></button>
                        </span>
                    </div>
                    <router-link class="nav-link" to="/" @click="clickNavLink()">
                        <div class="sb-nav-link-icon">
                            <!-- <i class="fas fa-tachometer-alt"></i> -->
                            <img src="../assets/ico/home-heart.svg" alt="Home" />
                        </div>
                        <div>Home</div>
                    </router-link>

                    <router-link class="nav-link" to="/Survey" v-if="this.getView !== 'doctorPage'" @click="clickNavLink()">
                        <div class="sb-nav-link-icon">
                            <!-- <i class="fas fa-tachometer-alt"></i> -->
                            <img src="../assets/ico/mood.svg" alt="Survey" />
                        </div>
                        <div>今天感覺如何？</div>
                    </router-link>

                    <router-link class="nav-link" to="/MedicationRecord" v-if="getIsLogin && this.getView !== 'doctorPage'" @click="clickNavLink()">
                        <div class="sb-nav-link-icon">
                            <!-- <i class="fas fa-tachometer-alt"></i> -->
                            <img src="../assets/ico/documents.svg" alt="MedicationRecord" />
                        </div>
                        <div>每日服藥狀態</div>
                    </router-link>

                    <router-link class="nav-link" to="/PersonalLog" v-if="getIsLogin && this.getView !== 'doctorPage'" @click="clickNavLink()">
                        <div class="sb-nav-link-icon">
                            <!-- <i class="fas fa-tachometer-alt"></i> -->
                            <img src="../assets/ico/calendar-edit.svg" alt="PersonalLog" />
                        </div>
                        <div>個人紀錄查詢</div>
                    </router-link>

                    <router-link class="nav-link" to="/AboutFM" v-if="this.getView !== 'doctorPage'" @click="clickNavLink()">
                        <div class="sb-nav-link-icon">
                            <!-- <i class="fas fa-tachometer-alt"></i> -->
                            <img src="../assets/ico/info-circle.svg" alt="AboutFM" />
                        </div>
                        <div>認識纖維肌痛症</div>
                    </router-link>

                    <!-- Doctor SideNav -->
                    <router-link
                        class="nav-link"
                        to="/Doctor/SearchPatient"
                        v-if="getIsLogin && (this.$store.state.role === 'Doctor' || this.$store.state.role === 'Admin') && this.getView === 'doctorPage'"
                        @click="clickNavLink()"
                    >
                        <div class="sb-nav-link-icon">
                            <img src="../assets/ico/documents.svg" alt="Logout" />
                        </div>
                        <div>病患查詢</div>
                    </router-link>
                    <router-link
                        class="nav-link"
                        to="/Doctor/TodayPatient"
                        v-if="getIsLogin && this.$store.state.role === 'Doctor' && this.getView === 'doctorPage'"
                        @click="clickNavLink()"
                    >
                        <div class="sb-nav-link-icon">
                            <img src="../assets/ico/calendar-edit.svg" alt="Logout" />
                        </div>
                        <div>本日掛號</div>
                    </router-link>
                    <router-link
                        class="nav-link"
                        to="/Doctor/SearchMedicine"
                        v-if="getIsLogin && (this.$store.state.role === 'Doctor' || this.$store.state.role === 'Admin') && this.getView === 'doctorPage'"
                        @click="clickNavLink()"
                    >
                        <div class="sb-nav-link-icon">
                            <img src="../assets/ico/med_search.svg" alt="Logout" />
                        </div>
                        <div>藥物查詢與設定</div>
                    </router-link>
                    <router-link
                        class="nav-link"
                        to="/Doctor/SearchMedicineCombination"
                        v-if="getIsLogin && (this.$store.state.role === 'Doctor' || this.$store.state.role === 'Admin') && this.getView === 'doctorPage'"
                        @click="clickNavLink()"
                    >
                        <div class="sb-nav-link-icon">
                            <img src="../assets/ico/med_records.svg" alt="Logout" />
                        </div>
                        <div>常用藥物組合設定</div>
                    </router-link>

                    <router-link class="nav-link" to="/Settings" v-if="getIsLogin" @click="clickNavLink()">
                        <div class="sb-nav-link-icon">
                            <img src="../assets/ico/setting.svg" alt="Settings" />
                        </div>
                        <div>設定</div>
                    </router-link>

                    <router-link class="nav-link" to="/Login" v-if="!getIsLogin" @click="clickNavLink()">
                        <!-- <router-link class="nav-link" to="/Login" v-if="!isLogin"> -->
                        <img src="../assets/ico/med_records.svg" alt="Sign up and Login" />
                        <div class="sb-nav-link-icon"></div>
                        <div>註冊與登入</div>
                    </router-link>

                    <router-link class="nav-link" to="/Report" v-if="this.$store.state.role !== 'Admin'" @click="clickNavLink()">
                        <div class="sb-nav-link-icon">
                            <img src="../assets/ico/question.svg" alt="Report" />
                        </div>
                        <div>問題回報</div>
                    </router-link>

                    <router-link class="nav-link" to="/admin/Management" v-if="this.$store.state.role === 'Admin' && this.getView === 'doctorPage'" @click="clickNavLink()">
                        <div class="sb-nav-link-icon">
                            <!-- <img src="../assets/ico/question.svg" alt="Management" /> -->
                            <img src="../assets/ico/info-circle.svg" />
                        </div>
                        <div>系統管理</div>
                    </router-link>

                    <router-link class="nav-link" to="/Logout" v-if="getIsLogin" @click="clickNavLink()">
                        <div class="sb-nav-link-icon">
                            <img src="../assets/ico/logout.svg" alt="Logout" />
                        </div>
                        <div>登出</div>
                    </router-link>
                </div>
                <div class="sb-sidenav-footer">
                    <div class="sb-custom-sticky">
                        <div v-cloak
                            v-if="!(this.$store.state.role === 'Doctor' || this.$store.state.role === 'Admin')"
                            class="col-12 d-flex"
                            style="align-items: center; justify-content: center"
                        >
                            <img v-cloak
                                v-if="!(this.$store.state.role === 'Doctor' || this.$store.state.role === 'Admin')"
                                style="width: 30%; position: relative"
                                src="/img/台灣疼痛醫學會_LOGO(180).png"
                                alt=""
                            />
                        </div>
                        <div class="small m-1 row">
                            <router-link class="nav-link p-0" to="/" @click="viewDoctorPage()">
                                <div class="col m-0">Copyright © 2022 疼痛醫學會</div>
                            </router-link>
                        </div>
                        <div class="small m-1 row">
                            <!-- <router-link class="nav-link p-0 col text-end" to="/#"> ・版權宣告 </router-link> -->
                            <router-link class="nav-link p-0 col small" to="/PrivacyPolicy">隱私權宣告</router-link>
                        </div>
                        <div class="row" v-cloak v-if="(this.$store.state.role === 'Doctor' || this.$store.state.role === 'Admin')">
                            <div class="col-12 d-flex" style="align-items: center; justify-content: center">
                                <img style="width: 30%; position: relative" src="/img/台灣疼痛醫學會_LOGO(180).png" alt="" />
                                <span class="m-2">|</span>
                                <img style="width: 40%; height: 75%" src="/img/viatris_logo.svg" alt="" />
                            </div>
                            <div class="col-6"></div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
export default {
    name: "PatientSideNav",
    data() {
        return {};
    },
    props: {
        msg: String,
    },
    mounted() {
        this.$store.dispatch("UPDATE_NAME");
        let theme = localStorage.getItem("theme");
        if (theme == "blue") {
            this.changeColor("blue");
        } else {
            this.changeColor("pink");
        }
    },
    updated() {
        // console.log('side nav update---------');
    },
    computed: {
        getIsLogin() {
            return this.$store.state.isLogin;
        },
        getRole() {
            return this.$store.state.role;
        },
        getView() {
            if (this.$store.state.view == "" && (this.$store.state.role === "Admin" || this.$store.state.role === "Doctor")) {
                this.$store.commit("SET_VIEW", "doctorPage");
            } else if (this.$store.state.view == "") {
                // this.$store.commit("SET_VIEW", "patientPage");
            }
            return this.$store.state.view;
        },
    },
    methods: {
        clickNavLink() {
            let browserWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            if (browserWidth < 1025) {
                document.querySelector("#sidebarToggle").click();
            }
        },
        changeColor(color) {
            // console.log(color);
            const theme = document.querySelector("#theme");
            // console.log(theme.getAttribute('href'));
            if (color === "blue") {
                theme.setAttribute("href", "/css/blue.css");
                localStorage.setItem("theme", "blue");
                this.$store.commit("SET_THEME", "blue");
            } else {
                theme.setAttribute("href", "/css/pink.css");
                localStorage.setItem("theme", "pink");
                this.$store.commit("SET_THEME", "pink");
            }
            return;
        },
        viewDoctorPage() {
            this.getView;
            if (this.$store.state.role === "Admin" || this.$store.state.role === "Doctor") {
                if (this.$store.state.view === "doctorPage") {
                    this.$store.commit("SET_VIEW", "patientPage");
                } else if (this.$store.state.view === "patientPage") {
                    this.$store.commit("SET_VIEW", "doctorPage");
                } else {
                }
            } else {
            }
        },
    },
};
</script>

<style scoped>
/* background-color: #FFEAEE;
	background-color:#FFC4D0;
	background-color: #DFF0FF;
	background-color:#A8D5FF; */

[v-cloak] {
	display: none !important;
}

#blue {
    background-color: #a8d5ff;
}

#pink {
    background-color: #ffc4d0;
}

button.color {
    height: 25px;
    width: 25px;
    border: 2px solid black;
    outline: none;
    border-radius: 50%;
    cursor: pointer;
}

button.color:hover {
    border: 3px solid black;
    opacity: 0.9;
}

.sb-sidenav-custom .sb-sidenav-menu .sb-sidenav-menu-heading {
    color: #adb5bd;
}
.sb-sidenav-custom .sb-sidenav-menu .nav-link {
    color: #212529;
}
.sb-sidenav-custom .sb-sidenav-menu .nav-link .sb-nav-link-icon {
    color: #adb5bd;
}
.sb-sidenav-custom .sb-sidenav-menu .nav-link .sb-sidenav-collapse-arrow {
    color: #adb5bd;
}
.sb-sidenav-custom .sb-sidenav-menu .nav-link:hover {
    color: #0d6efd;
}
.sb-sidenav-custom .sb-sidenav-menu .nav-link.active {
    color: #0d6efd;
}
.sb-sidenav-custom .sb-sidenav-menu .nav-link.active .sb-nav-link-icon {
    color: #0d6efd;
}
.sb-sidenav-custom .sb-sidenav-footer {
    /* background-color: #e9ecef; */
}
.sb-custom-sticky {
    /* position: sticky; */
    /* top : 200px; */
    height: 240px;
    display: flex !important;
    align-items: stretch;
    flex-direction: column;
    justify-content: flex-end;
}
.sb-sidenav .sb-sidenav-menu .nav {
    flex-direction: column;
    flex-wrap: nowrap;
    min-height: 429px;
    height: calc(100vh - 320px);
}
</style>
