<template>
    <section class="bg">
        <div class="row title py-3">
            <h2 class="">個人紀錄</h2>
        </div>
        <br />
        <div class="row">
            <div class="col-lg-1"></div>
            <div class="col-lg-10">
                <div class="row" v-show="isNoData">
                    <div class="col-lg-3"></div>
                    <div class="col-lg-6">
                        <p class="fw-bold text-start my-4">您尚未有問卷或是服藥紀錄！</p>
                    </div>
                    <div class="col-lg-3"></div>
                </div>
                <table v-show="!isNoData" class="table table-striped">
                    <thead>
                        <th>日期</th>
                        <th>WPI</th>
                        <th>Pain Scale</th>
                        <th>SS</th>
                        <th>FIQR</th>
                        <th>服藥紀錄</th>
                    </thead>
                    <tbody>
                        <tr v-for="(item, key) in dataList" :key="key">
                            <td>{{ item.date }}</td>
                            <td>{{ item.wpi }}</td>
                            <td>{{ item.ps }}</td>
                            <td>{{ item.ss }}</td>
                            <td>{{ item.fiqr }}</td>
                            <td>{{ item.takeMedicineAverage }}%</td>
                            <!-- TODO: -->
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-lg-1"></div>
        </div>
    </section>
</template>

<style scoped>
@media (max-width: 992px) {
}

@media (min-width: 992px) {
}
</style>

<script>
// @ is an alias to /src

export default {
    name: "PersonalLog",
    data() {
        return {
            dataList: null,
            isNoData: true,
        };
    },
    components: {},
    mounted() {
        this.getLogs();
    },
    methods: {
        getLogs() {
            // console.log(this.dataList);
            this.$axios({
                url: "/personalLog",
                method: "GET",
            }).then((res) => {
                // console.log(res.data);
                if (res.data.data == null) {
                } else if (res.data.code === 200) {
                    this.isNoData = false;
                    for (const d of res.data.data) {
                        d.date = d.date.split("T")[0];
                        d.takeMedicineAverage = (d.takeMedicineAverage*100).toFixed(0);
                    }
                    this.dataList = res.data.data;
                } else if (res.data.code === 400) {
                    // TODO: 錯誤原因
                    alert(`${res.data.msg}`);
                }
            });
            // .catch((error) => {
            // 	console.log("something error after submit!!");
            // });
        },
    },
};
</script>
