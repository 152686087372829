import { createRouter, createWebHashHistory } from "vue-router";
import store from "../store";
import axios from "../axios.js";
// import api from '../api/authApi.js'

// required
// const Appointment = () => import("../views/patient/Appointment.vue");
import Appointment from '../views/patient/Appointment.vue'

// common
// import SignUp from '../views/account/SignUp';
import Settings from '../views/account/Settings.vue'
import Report from '../views/account/Report.vue';
// import Login from '../views/account/Login.vue'
// import Logout from '../views/account/Logout.vue'
// import ForgetPassword from '../views/account/ForgetPassword.vue'
// import ResetPassword from '../views/account/ResetPassword.vue'
const SignUp = () => import("../views/account/SignUp");
// const Settings = () => import("../views/account/Settings.vue");
// const Report = () => import("../views/account/Report.vue");
const Login = () => import("../views/account/Login.vue");
const Logout = () => import("../views/account/Logout.vue");
const ForgetPassword = () => import("../views/account/ForgetPassword.vue");
const ResetPassword = () => import("../views/account/ResetPassword.vue");
const PrivacyPolicy = () => import("@/views/account/PrivacyPolicy.vue");

// cold page
// import AboutFM2 from "../views/patient/AboutFM2.vue";
// import AboutTFA from "../views/patient/about/AboutTFA.vue";
// import CareYourself from "../views/patient/about/CareYourself.vue";
// import DoctorTeach from "../views/patient/about/DoctorTeach.vue";
// import Link from "../views/patient/about/Link.vue";
// import SportBetter from "../views/patient/about/SportBetter.vue";
// import WhatIsFM from "../views/patient/about/WhatIsFM.vue";

const AboutFM2 = () => import("../views/patient/AboutFM2.vue");
const AboutTFA = () => import("../views/patient/about/AboutTFA.vue");
const CareYourself = () => import("../views/patient/about/CareYourself.vue");
const DoctorTeach = () => import("../views/patient/about/DoctorTeach.vue");
const Link = () => import("../views/patient/about/Link.vue");
const SportBetter = () => import("../views/patient/about/SportBetter.vue");
const WhatIsFM = () => import("../views/patient/about/WhatIsFM.vue");

// patient
// import Survey from "../views/patient/Survey.vue";
import MedicationRecord from "../views/patient/MedicationRecord.vue";
import PersonalLog from "../views/patient/PersonalLog.vue";
const Survey = () => import("../views/patient/Survey.vue");
// const MedicationRecord = () => import("../views/patient/MedicationRecord.vue");
// const PersonalLog = () => import("../views/patient/PersonalLog.vue");

// doctor
// import Dashboard from "../views/doctor/Dashboard.vue";
// import SearchPatient from "../views/doctor/SearchPatient.vue";
// import TodayPatient from "../views/doctor/TodayPatient.vue";
// import Diagnose from "../views/doctor/Diagnose.vue";
// import SearchMedicine from "../views/doctor/SearchMedicine.vue";
// import Medicine from "../views/doctor/Medicine.vue";
// import SearchMedicineCombination from "../views/doctor/SearchMedicineCombination.vue";
// import MedicineCombination from "../views/doctor/MedicineCombination.vue";
const Dashboard = () => import("../views/doctor/Dashboard.vue");
const SearchPatient = () => import("../views/doctor/SearchPatient.vue");
const TodayPatient = () => import("../views/doctor/TodayPatient.vue");
const Diagnose = () => import("../views/doctor/Diagnose.vue");
const SearchMedicine = () => import("../views/doctor/SearchMedicine.vue");
const Medicine = () => import("../views/doctor/Medicine.vue");
const SearchMedicineCombination = () => import("../views/doctor/SearchMedicineCombination.vue");
const MedicineCombination = () => import("../views/doctor/MedicineCombination.vue");

const routes = [

    {
        path: "/",
        name: "Index",
        component: () => {
            return import(/* webpackChunkName: "about" */ "../views/patient/Index2.vue");
            // const type = 'patient';
            // if (type === 'doctor') {
            // 	return import(/* webpackChunkName: "about" */ '../views/doctor/Index.vue');
            // } else {
            //	return import(/* webpackChunkName: "about" */ '../views/patient/Index2.vue');
            // }
        },
    },
	{
        path: "/PrivacyPolicy",
        name: "PrivacyPolicy",
        component: PrivacyPolicy,
    },
    {
        path: "/Survey",
        name: "Survey",
        component: Survey,
    },
    {
        path: "/MedicationRecord",
        name: "MedicationRecord",
        component: MedicationRecord,
    },
    {
        path: "/PersonalLog",
        name: "PersonalLog",
        component: PersonalLog,
    },
    {
        path: "/AboutFM",
        name: "AboutFM",
        component: AboutFM2,
    },

    {
        path: "/Report",
        name: "Report",
        component: Report,
    },

    {
        path: "/Settings",
        name: "Settings",
        component: Settings,
    },

    {
        path: "/Login",
        name: "Login",
        component: Login,
    },
    {
        path: "/SignUp",
        name: "SignUp",
        component: SignUp,
    },
    {
        path: "/Logout",
        name: "Logout",
        component: Logout,
    },
    {
        path: "/ForgetPassword",
        name: "ForgetPassword",
        component: ForgetPassword,
    },
    {
        path: "/ResetPassword/:rkey",
        name: "ResetPassword",
        component: ResetPassword,
    },

    {
        path: "/AboutTFA",
        name: "AboutTFA",
        component: AboutTFA,
    },
    {
        path: "/CareYourself",
        name: "CareYourself",
        component: CareYourself,
    },
    {
        path: "/DoctorTeach",
        name: "DoctorTeach",
        component: DoctorTeach,
    },
    {
        path: "/Link",
        name: "Link",
        component: Link,
    },
    {
        path: "/SportBetter",
        name: "SportBetter",
        component: SportBetter,
    },
    {
        path: "/WhatIsFM",
        name: "WhatIsFM",
        component: WhatIsFM,
    },
    {
        path: "/Appointment/:hospitalName",
        name: "Appointment",
        component: Appointment,
    },

    // doctor
    {
        path: "/Doctor/Dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {
            isDoctor: true,
        },
    },
    {
        path: "/Doctor/SearchPatient",
        name: "SearchPatient",
        component: SearchPatient,
        meta: {
            isDoctor: true,
        },
    },
    {
        path: "/Doctor/TodayPatient",
        name: "TodayPatient",
        component: TodayPatient,
        meta: {
            isDoctor: true,
        },
    },
    {
        path: "/Doctor/Diagnose/:type/:userId",
        name: "Diagnose",
        component: Diagnose,
        meta: {
            isDoctor: true,
        },
    },
    {
        path: "/Doctor/SearchMedicine",
        name: "SearchMedicine",
        component: SearchMedicine,
        meta: {
            isDoctor: true,
        },
    },
    {
        path: "/Doctor/Medicine/:id",
        name: "Medicine",
        component: Medicine,
        meta: {
            isDoctor: true,
        },
    },
    {
        path: "/Doctor/SearchMedicineCombination",
        name: "SearchMedicineCombination",
        component: SearchMedicineCombination,
        meta: {
            isDoctor: true,
        },
    },
    {
        path: "/Doctor/MedicineCombination/:id",
        name: "MedicineCombination",
        component: MedicineCombination,
        meta: {
            isDoctor: true,
        },
    },
    {
        path: "/admin/Management",
        name: "Management",
		component: () => {
            return import("../views/admin/Management.vue");
        },
        meta: {
            isDoctor: true,
        },
    },
	{
        path: "/admin/Hospital/:id",
        name: "Hospital",
		component: () => {
            return import("../views/admin/Hospital.vue");
        },
        meta: {
            isDoctor: true,
        },
    },
    {
        path: "/admin/ReportConfirm/:id",
        name: "ReportConfirm",
		component: () => {
            return import("../views/admin/ReportConfirm.vue");
        },
        meta: {
            isDoctor: true,
        },
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

// TODO: survey最後完成時檢查是否登入 如未登入顯示評估狀況並建議建立帳號 已登入則寫入
const needAccess = ["/MedicationRecord", "/PersonalLog", "/Settings", "/Appointment/", "/appointment/"];
// const needAccess = [""];

// callback = (to,from,next) =>{}
router.beforeEach(async (to, from) => {
    // doctor
    const role = localStorage.getItem("role");
    if (to.matched.some((record) => record.meta.isDoctor)) {
        if (role === "Admin" || role === "Doctor") {
            return true;
        } else {
            return { name: "Index" };
        }
    }

    let result;
    if (to.path.includes(needAccess) || to.path.toLowerCase().includes("/appointment/")) {
        // console.log("isLogin Start");
        result = await axios({
            url: "/a/isAuthenticated?",
            method: "GET",
        })
            .then((res) => {
                // console.log(`isLogin = ${res.data.msg}`);
                // this.$store.commit("SET_USERNAME", "test");
                return res.data.msg;
            })
            .catch((error) => {
                console.log("beforeEach error");
                alert("網路連線異常!");
            });

        if (result == "true") {
            // console.log('is Login..');
            return true;
        } else if (result != "true") {
            // console.log("is not login, go to login page");
            sessionStorage.setItem("wantRedirect", to.fullPath);
            return { name: "Login", query: { redirect: to.fullPath } };
        }
        // console.log("isLogin End");
    } else {
        // 不需驗證畫面
        return true;
    }
});

export default router;
