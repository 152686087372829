<template>
    <Main v-cloak></Main>
</template>

<script>
import Main from "@/views/Main.vue";

export default {
    components: {
        Main,
    },
    mounted() {
        const sidebarToggle = document.body.querySelector("#sidebarToggle");
        if (sidebarToggle) {
            // Uncomment Below to persist sidebar toggle between refreshes
            // if (localStorage.getItem('sb|sidebar-toggle') === 'true') {
            //     document.body.classList.toggle('sb-sidenav-toggled');
            // }
            sidebarToggle.addEventListener("click", (event) => {
                event.preventDefault();
                document.body.classList.toggle("sb-sidenav-toggled");
                localStorage.setItem("sb|sidebar-toggle", document.body.classList.contains("sb-sidenav-toggled"));
            });
        }
    },
};
</script>

<style>
[v-cloak] {
	display: none !important;
}
</style>
