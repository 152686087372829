<template>
    <div class="container-fluid px-5 mb-5 bg">
        <div class="row title py-3">
            <h2 class="">設定</h2>
        </div>
        <div class="row text-start">
            <div class="row title py-3">
                <h3 class="">修改基本資料</h3>
            </div>
            <div class="col-lg-6 col-md-12 mb-3">
                <label for="Username" class="form-label fw-bold">帳號/信箱<span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="Username" v-model="dataForm.username" placeholder="" />
            </div>
            <div class="col-lg-6 col-md-12 mb-3">
                <label for="Phone" class="form-label fw-bold">聯絡電話（行動電話或區碼市話）<span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="Phone" v-model="dataForm.phone" placeholder="" />
            </div>
            <div class="col-lg-12 col-md-12 mb-3">
                <hr />
                <label for="OldPassword" class="form-label fw-bold">舊密碼（如不修改請留空）</label>
                <input type="password" class="form-control" id="oldPassword" v-model="dataForm.oldPassword" placeholder="請輸入舊密碼" />
            </div>

            <div class="col-lg-12 col-md-12 mb-3">
                <label for="Password" class="form-label fw-bold">新密碼</label>
                <input type="password" class="form-control" id="Password" v-model="dataForm.password" placeholder="請輸入新密碼" />
            </div>
            <div class="col-lg-12 col-md-12 mb-3">
                <label for="confirmPassword" class="form-label fw-bold">確認新密碼</label>
                <input type="password" class="form-control" id="confirmPassword" v-model="dataForm.confirmPassword" placeholder="請再次輸入新密碼" />
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 text-center">
                <button type="button" @click="goback()" class="btn btn-purple2 btn-w mx-2">回上一頁</button>
                <button type="button" @click="personalDataChange()" class="btn btn-purple btn-w mx-2">確認修改</button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, reactive, getCurrentInstance } from "vue";
export default {
    setup(props, ctx) {
        const currentInstance = getCurrentInstance();
        const { $bootstrap } = currentInstance.appContext.config.globalProperties;
    },
    mounted() {
        this.$axios({
            url: "/setting/personalDataChange",
            method: "GET",
        })
            .then((res) => {
                if (res.data.code === 200) {
                    this.dataForm.phone = res.data.data.phone;
                    this.dataForm.username = res.data.data.username;
                } else if (res.data.code === 400) {
                    alert(`${res.data.msg}!!`);
                }
            })
            .catch((error) => {
                // console.log("something error after submit!!");
                // console.log(error);
            });
    },
    data() {
        return {
            dataForm: {
                username: "",
                phone: "",
                oldPassword: "",
                password: "",
                confirmPassword: "",
            },
        };
    },
    methods: {
        personalDataChange() {
            let dataCorrect = true; //TODO: 暫時設定未來檢核
            if (dataCorrect == false) {
                // TODO:檢核欄位是否填寫與正確性與送出
                return;
            } else {
                const reqData = this.dataForm;
                this.$axios({
                    url: "/setting/personalDataChange",
                    method: "PATCH",
                    data: reqData,
                })
                    .then((res) => {
                        console.log(res.data);
                        if (res.data.code === 200) {
                            // TODO: 確認訊息G
                            alert(`修改成功！`);
                        } else if (res.data.code === 400) {
                            // TODO: 錯誤原因
                            alert(`${res.data.msg}!!`);
                        }
                    })
                    .catch((error) => {
                        console.log("something error after submit!!");
                    });
            }
        },
        goback() {
            if (window.history.length <= 1) {
                this.$router.push({ path: "/" });
                return false;
            } else {
                this.$router.back();
            }
        },
    },
};
</script>

<style scoped>
@media (max-width: 992px) {
}

@media (min-width: 992px) {
}
</style>
