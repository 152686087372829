<template>
    <nav class="sb-topnav navbar navbar-expand bg-custom">
        <!-- <nav class="sb-topnav navbar navbar-expand navbar-dark bg-custom"> -->
        <!-- Sidebar Toggle-->
        <!-- Navbar Brand-->
        <a class="navbar-brand ps-3 text-dark" href="/">纖維肌痛症</a>
        <button class="btn btn-link btn-sm order-first order-lg-0 ms-4 me-lg-0 text-dark" id="sidebarToggle" href="#!">
            <i class="fas fa-bars"></i>
        </button>

        <span class="d-md-inline-block form-inline ms-auto me-2 me-md-4 my-2 my-md-0">{{ this.$store.state.name }} 您好</span>
    </nav>
</template>

<script>
import { ref, reactive, getCurrentInstance } from "vue";
export default {
    name: "PatientHeader",
    data() {
        return {
            name: "訪客",
        };
    },
    setup(props, ctx) {
        const currentInstance = getCurrentInstance();
        const { $axios, $http, $message, $route } = currentInstance.appContext.config.globalProperties;
    },
    methods: {
        
    },
    created() {
        // this.getName();
    },
};
</script>

<style></style>
