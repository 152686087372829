<template>
    <patient-header />
    <div id="layoutSidenav">
        <!-- <component :is="getSideNav" /> -->
        <side-nav />
        <div id="layoutSidenav_content">
            <router-view />
            <Footer></Footer>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import PatientHeader from "@/components/PatientHeader.vue";
import Footer from "@/components/Footer.vue";
import SideNav from "@/components/SideNav.vue";
// import SideNav from '../components/SideNav.vue';

export default {
    name: "Main",
    components: {
        Footer,
        PatientHeader,
        SideNav,
    },
    data() {
        return {
            // role: this.$store.state.role,
            // view: this.$store.state.view,
        };
    },
    computed: {
        // getSideNav() {
        //     this.$store.dispatch("UPDATE_ROLE");
        //     if (this.getRole === "Admin" || this.getRole === "Doctor") {
        //         if (this.getView == "patientPage") {
        //             return "PatientSideNav";
        //         }
        //         return "DoctorSideNav";
        //     }
        //     return "PatientSideNav";
        // },
        // getView() {
        //     return this.$store.state.view;
        // },
        // getRole() {
        //     return this.$store.state.role;
        // },
    },
    mounted() {
        this.$store.dispatch("UPDATE_ROLE");
    },
    methods: {},
};
</script>

<style>
.noSelect {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -o-user-select: none !important;
    user-select: none !important;
}
.noDrag {
    -webkit-user-drag: none;
}
/* :root {
    --purple: #9400d3;
} */
.btn-purple {
    color: #fff !important;
    background-color: darkviolet !important;
    border-color:  darkviolet !important;
}
.btn-purple2 {
    color:  darkviolet !important;
    background-color: #fff;
    border-color: darkviolet !important;
}
</style>
