<template>
    <section class="bg">
        <div class="row py-3">
            <h2 class="">服藥狀態</h2>
        </div>
        <div class="row py-3" v-show="intro == true">
            <div class="col-sm-3"></div>
            <div class="col-sm-6">
                <div class="row my-2 px-2">
                    <div class="col-12 text-start px-3" style="min-height: 100px">
                        <p class="fw-bold text-start">每日紀錄服藥狀態有助於醫師對您的病情的診斷與治療。</p>
                        <p class="fw-bold text-start">請依照當天的服藥總量進行填寫，謝謝！</p>
                    </div>
                    <div class="col-12 text-start my-4 px-4" v-show="needTake === 'Y'">
                        <label for="medRecordDate" class="form-label fw-bold">服藥日期</label>
                        <input type="date" class="form-control" id="medRecordDate" name="medRecordDate" v-model="medRecordDate" />
                    </div>
                    <div class="col-12">
                        <button class="btn btn-purple m-2" v-show="needTake === 'Y'" @click="start()">開始填寫</button>
                    </div>
                    <div class="col-12 text-start my-4 px-3" v-show="needTake === 'N'">
                        <p class="fw-bold text-start">您尚未有服藥的醫囑！</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-3"></div>
        </div>

        <div class="row py-3" v-show="intro == false && result == false">
            <div class="col-sm-3"></div>
            <div class="col-sm-6">
                <div class="row my-2 px-2">
                    <div class="col-12 text-start px-3" style="min-height: 100px">
                        <p class="fw-bold text-start">每日紀錄服藥狀態有助於醫師對您的病情的診斷與治療。</p>
                        <p class="fw-bold text-start">請依照當天的服藥總量進行填寫，謝謝！</p>
                    </div>
                    <div class="col-12 text-start my-4">
                        <table class="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th>藥物名稱</th>
                                    <th>醫囑單日劑量</th>
                                    <th>實際單日使用劑量</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="medicalData.medName1 != null">
                                    <td>{{ medicalData.medName1 }}</td>
                                    <td>{{ medicalData.medDose1 }}{{ medicalData.medUnit1 }}</td>
                                    <td>
                                        <select name="medSelectDose1" id="medSelectDose1" v-model="medSelectDose1" class="form-select">
                                            <option value="2">2倍份量</option>
                                            <option value="1" selected>標準份量</option>
                                            <option value="0.5">1/2份量</option>
                                            <option value="0">未服用</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr v-if="medicalData.medName2 != null">
                                    <td>{{ medicalData.medName2 }}</td>
                                    <td>{{ medicalData.medDose2 }}{{ medicalData.medUnit2 }}</td>
                                    <td>
                                        <select name="medSelectDose2" id="medSelectDose2" v-model="medSelectDose2" class="form-select">
                                            <option value="2">2倍份量</option>
                                            <option value="1" selected>標準份量</option>
                                            <option value="0.5">1/2份量</option>
                                            <option value="0">未服用</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr v-if="medicalData.medName3 != null">
                                    <td>{{ medicalData.medName3 }}</td>
                                    <td>{{ medicalData.medDose3 }}{{ medicalData.medUnit3 }}</td>
                                    <td>
                                        <select name="medSelectDose3" id="medSelectDose3" v-model="medSelectDose3" class="form-select">
                                            <option value="2">2倍份量</option>
                                            <option value="1" selected>標準份量</option>
                                            <option value="0.5">1/2份量</option>
                                            <option value="0">未服用</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr v-if="medicalData.medName4 != null">
                                    <td>{{ medicalData.medName4 }}</td>
                                    <td>{{ medicalData.medDose4 }}{{ medicalData.medUnit4 }}</td>
                                    <td>
                                        <select name="medSelectDose4" id="medSelectDose4" v-model="medSelectDose4" class="form-select">
                                            <option value="2">2倍份量</option>
                                            <option value="1" selected>標準份量</option>
                                            <option value="0.5">1/2份量</option>
                                            <option value="0">未服用</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr v-if="medicalData.medName5 != null">
                                    <td>{{ medicalData.medName5 }}</td>
                                    <td>{{ medicalData.medDose5 }}{{ medicalData.medUnit5 }}</td>
                                    <td>
                                        <select name="medSelectDose5" id="medSelectDose5" v-model="medSelectDose5" class="form-select">
                                            <option value="2">2倍份量</option>
                                            <option value="1" selected>標準份量</option>
                                            <option value="0.5">1/2份量</option>
                                            <option value="0">未服用</option>
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div></div>
                    </div>
                    <div class="col-12">
                        <div class="btn-border">
                            <button class="btn btn-back" @click="back()">上一頁</button>
                            <button class="btn btn-forward font-color-purple" @click="save()">儲存</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-3"></div>
        </div>

        <div class="row py-3" v-show="result == true">
            <div class="col-sm-3"></div>
            <div class="col-sm-6">
                <div class="row my-2 px-2">
                    <div class="col-12 text-start px-3" style="min-height: 100px">
                        <p class="fw-bold text-start">感謝您的填寫，每日紀錄服藥資訊有助於醫師對您的病情的診斷與治療，以及後續進行纖維肌痛症的研究。</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-3"></div>
        </div>
    </section>
</template>

<style scoped>
.btn-border {
    border-radius: 30px 30px 30px 30px;
    /* border: 1px red solid; */
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
    width: 285px;
    height: 60px;
    margin: auto;
}

.btn-back {
    border-radius: 30px 0 0 30px;
    border-right: 0;
    padding-right: 0;
    width: 140px;
    height: 60px;
    font-weight: bold;
    border-right-color: lightgray;
}
.btn-forward {
    border-radius: 0 30px 30px 0;
    /* border-left: 0; */
    padding-left: 0;
    width: 140px;
    height: 60px;
    font-weight: bold;
    border-left-color: lightgray;
}

.font-color-purple {
    /* color: blueviolet; */
    color: darkviolet;
}
@media (max-width: 992px) {
}

@media (min-width: 992px) {
}
</style>

<script>
// @ is an alias to /src
import { getCurrentInstance } from "vue";

export default {
    name: "MedicationRecord",
    data() {
        return {
            needTake: "LOADING",
            intro: true,
            result: false,
            medRecordDate: "2022-01-01",
            medicalData: {},
            medSelectDose1: 1,
            medSelectDose2: 1,
            medSelectDose3: 1,
            medSelectDose4: 1,
            medSelectDose5: 1,
            sendForm: {
                takeMedicineDate: null,
                medId1: null,
                medDose1: null,
                medId2: null,
                medDose2: null,
                medId3: null,
                medDose3: null,
                medId4: null,
                medDose4: null,
                medId5: null,
                medDose5: null,
            },
        };
    },
    components: {},
    setup() {
        const currentInstance = getCurrentInstance();
        const { $axios, $http, $message, $route } = currentInstance.appContext.config.globalProperties;
    },
    mounted() {
        const now = new Date();
        this.medRecordDate = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, "0")}-${now.getDate().toString().padStart(2, "0")}`;
        this.getMedicalRecord();
    },
    methods: {
        start() {
            this.intro = false;
            this.medDose1 = 1;
            this.medDose2 = 1;
            this.medDose3 = 1;
            this.medDose4 = 1;
            this.medDose5 = 1;
        },
        back() {
            this.intro = true;
        },
        save() {
            this.sendForm.takeMedicineDate = this.medRecordDate;
            if (this.medicalData.medId1 != null) {
                this.sendForm.medId1 = this.medicalData.medId1;
                this.sendForm.medDose1 = this.medicalData.medDose1 * this.medSelectDose1;
                // console.log(this.sendForm.medDose1);
            }
            if (this.medicalData.medId2 != null) {
                this.sendForm.medId2 = this.medicalData.medId2;
                this.sendForm.medDose2 = this.medicalData.medDose2 * this.medSelectDose2;
            }
            if (this.medicalData.medId3 != null) {
                this.sendForm.medId3 = this.medicalData.medId3;
                this.sendForm.medDose3 = this.medicalData.medDose3 * this.medSelectDose3;
            }
            if (this.medicalData.medId4 != null) {
                this.sendForm.medId4 = this.medicalData.medId4;
                this.sendForm.medDose4 = this.medicalData.medDose4 * this.medSelectDose4;
            }
            if (this.medicalData.medId5 != null) {
                this.sendForm.medId5 = this.medicalData.medId5;
                this.sendForm.medDose5 = this.medicalData.medDose5 * this.medSelectDose5;
            }

            const reqData = this.sendForm;
            // console.log('send reqData')
            // console.log(reqData);
            this.$axios({
                url: "/takeMedicineRecord",
                method: "POST",
                data: reqData,
            })
                .then((res) => {
                    // console.log(res.data);
                    if (res.data.code === 200) {
                        // 確認訊息G
                        this.result = true;
                    } else if (res.data.code === 400) {
                        // 錯誤原因

                        alert(`${res.data.msg}!!`);
                    }
                })
                .catch((error) => {
                    console.log("something error after submit!!", error);
                });
        },
        getMedicalRecord() {
            this.$axios({
                url: "/medicalRecord",
                method: "GET",
            })
                .then((res) => {
                    // console.log(res.data);
                    if (res.data.code === 200) {
                        if (res.data.data != null) {
                            this.needTake = "Y";
                            this.medicalData = res.data.data;
                        } else {
                            this.needTake = "N";
                        }
                    } else if (res.data.code === 400) {
                    }
                })
                .catch((error) => {
                    console.log("something error after submit!!");
                });
        },
    },
};
</script>
