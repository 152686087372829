import { createStore } from "vuex";
import axios from "../axios.js";

export default createStore({
    state: {
        token: "",
        name: "訪客",
        role: "",
        isLogin: false,
        view: "",
        theme: "",
        // server: "https://fms-service.azurewebsites.net/api/v1",
        server: "https://fibromyalgia.pain.org.tw/api/v1",
        // server: "http://127.0.0.1/api/v1",
    },
    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token;
            localStorage.setItem("token", token);
        },
        DEL_TOKEN: (state, token) => {
            state.token = "";
            localStorage.removeItem("token");
        },
        SET_NAME: (state, name) => {
            state.name = name;
            localStorage.setItem("name", name);
        },
        DEL_NAME: (state, name) => {
            state.name = "";
            localStorage.removeItem("name");
        },
        SET_ISLOGIN: (state, s) => {
            state.isLogin = s;
        },
        SET_ROLE: (state, role) => {
            state.role = role;
            localStorage.setItem("role", role);
        },
        DEL_ROLE: (state, role) => {
            state.role = "";
            localStorage.removeItem("role");
        },
        SET_VIEW: (state, view) => {
            state.view = view;
            // localStorage.setItem("view", view);
        },
        DEL_VIEW: (state, view) => {
            state.view = "";
            // localStorage.removeItem("view");
        },
        SET_THEME: (state, theme) => {
            state.theme = theme;
            // localStorage.removeItem("view");
        },
    },
    actions: {
        UPDATE_NAME: ({ commit }) => {
            axios({
                url: "/a/getName",
                method: "GET",
            })
                .then((res) => {
                    // console.log(res.data);
                    if (res.data.code === 200) {
                        // console.log(`name = ${res.data.msg}`);
                        // this.$store.commit("SET_USERNAME", "test");
                        commit("SET_NAME", res.data.msg);
                        commit("SET_ISLOGIN", true);
                        if (res.data.msg === "訪客") {
                            commit("SET_ISLOGIN", false);
                        }
                        return res.data.msg;
                    } else if (res.data.code === 400 || res.data.code === 401) {
                        alert(`${res.data.msg}!!`);
                    }
                })
                .catch((error) => {
                    commit("SET_ISLOGIN", false);
                    commit("SET_NAME", "訪客");
                    commit("DEL_ROLE");
                    localStorage.removeItem("token");
                    console.log("something error when get name!! set the user is not login");
                    console.log(error);
                });
        },
        UPDATE_TOKEN: ({ commit }) => {
            const token = localStorage.getItem("token");
            if (!(token == "" || token == null)) {
                commit("SET_TOKEN", token);
                commit("SET_ISLOGIN", true);
            }
        },
        UPDATE_ROLE: async ({ commit }) => {
            await axios({
                url: "/a/getRole",
                method: "GET",
            })
                .then((res) => {
                    // console.log(res.data);
                    if (res?.data.code === 200) {
                        // console.log(`name = ${res.data.msg}`);
                        // this.$store.commit("SET_USERNAME", "test");
                        // console.log(res.data.msg);
                        if (res.data.msg === "訪客") {
                            return;
                        }
                        commit("SET_ROLE", res.data.msg);
                    } else if (res?.data.code === 400) {
                        alert(`${res.data.msg}!!`);
                    }
                })
                .catch((error) => {
                    // console.log("something error when get role!!", error);
                });
        },
    },
    modules: {},
});
