<template>
    <div class="container-fluid px-5 mb-5 bg">
        <div class="row title py-3">
            <h2 class="">問題回報</h2>
        </div>
        <div class="row text-start">
            <div class="col-lg-6 col-md-12 mb-3">
                <label for="Name" class="form-label fw-bold">姓名<span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="Name" v-model="dataForm.name" placeholder="" />
            </div>
            <div class="col-lg-6 col-md-12 mb-3">
                <label for="Email" class="form-label fw-bold">信箱</label>
                <input type="text" class="form-control" id="Email" v-model="dataForm.email" placeholder="" />
            </div>
            <div class="col-lg-6 col-md-12 mb-3">
                <label for="Phone" class="form-label fw-bold">聯絡電話（行動電話或區碼市話）</label>
                <input type="text" class="form-control" id="Phone" v-model="dataForm.phone" placeholder="" />
            </div>
            <div class="col-lg-12 col-md-12 mb-3">
                <label for="content" class="form-label fw-bold">問題<span class="text-danger">*</span></label>
                <textarea class="form-control" id="content" v-model="dataForm.content" style="height: 180px" placeholder="請輸入您的問題"> </textarea>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 text-center">
                <button type="button" @click="this.$router.go(-1)" class="btn btn-purple2 btn-w mx-2">回上一頁</button>
                <button type="button" @click="sendReport()" v-bind:disabled="formControl.btnDisable" class="btn btn-purple btn-w mx-2">確認送出</button>
            </div>
        </div>
    </div>
</template>

<style scoped>
@media (max-width: 992px) {
}

@media (min-width: 992px) {
}
</style>

<script>
// @ is an alias to /src

export default {
    name: "Report",
    data() {
        return {
            dataForm: {
                id: "",
                name: "",
                email: "",
                phone: "",
                content: "",
            },
            formControl: {
                btnDisable: false,
            },
        };
    },
    mounted() {
        this.$axios({
            url: "/setting/personalDataChange",
            method: "GET",
        })
            .then((res) => {
                if (res?.data.code === 200) {
                    this.dataForm.phone = res.data.data.phone;
                    this.dataForm.email = res.data.data.username;
                    this.dataForm.name = res.data.data.name;
                } else if (res?.data.code === 400) {
                    alert(`${res.data.msg}!!`);
                }
            })
            .catch((reason) => {
                // console.log(reason);// doesnt login..
				alert(reason);
            });
    },
    methods: {
        sendReport() {
            this.formControl.btnDisable = true;
            let alertMsg = "";
            if (this.dataForm.phone.trim() == "" && this.dataForm.email.trim() == "") {
                alertMsg = alertMsg + "請至少留一個聯絡方式\n";
            }
            if (this.dataForm.name.trim() == "") {
                alertMsg = alertMsg + "請填寫姓名欄位\n";
            }
            if (this.dataForm.content.trim() == "") {
                alertMsg = alertMsg + "請填寫一些實際內容\n";
            }
            if (alertMsg.length > 0) {
                alert(alertMsg);
                this.formControl.btnDisable = false;
                return;
            }

            const reqData = this.dataForm;
            this.$axios({
                url: "/a/report",
                method: "POST",
                data: reqData,
            })
                .then((res) => {
                    console.log(res.data);
                    if (res.data.code === 200) {
                        // TODO: 確認訊息G
                        alert(`已收到您的問題，會再盡快回復您！`);
                    } else if (res.data.code === 400) {
                        // TODO: 錯誤原因G
                        alert(`${res.data.msg}!!`);
                        this.formControl.btnDisable = false;
                    }
                })
                .catch((error) => {
                    console.log("something error after report data!!");
                });
        },
    },
};
</script>
