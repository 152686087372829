import { vue, createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// import "bootstrap/dist/css/bootstrap.min.css";
// import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/css/fontawesome.min.css";
import "@fortawesome/fontawesome-free/css/regular.min.css";
import "@fortawesome/fontawesome-free/css/solid.min.css";
import "@/assets/css/sideNav.css";
import "rangeslider.js/dist/rangeslider.css";
import "jquery-ui-dist/jquery-ui.min.css";
import "bootstrap-table/dist/bootstrap-table.min.css";
import "bootstrap-icons/font/bootstrap-icons.css"

// import 'bootstrap-icons';

// import 'bootstrap';
import * as bootstrap from 'bootstrap' ;

import axios from "./axios.js";
// import "@fortawesome/fontawesome-free/js/all.js";
import "@fortawesome/fontawesome-free/js/fontawesome.min.js";
import "@fortawesome/fontawesome-free/js/regular.min.js";
import "@fortawesome/fontawesome-free/js/solid.min.js";
import "@popperjs/core/dist/esm/popper.js";
// import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import "jquery-ui-dist/jquery-ui.min.js";
import "bootstrap-table/dist/bootstrap-table.min.js";

// import axios from "axios";
// import 'rangeslider.js'
// import $ from 'jquery'
// vue.prototype.$axios = axios;

const $ = require("jquery");
window.$ = $;
const app = createApp(App);
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$bootstrap = bootstrap;
// app.config.globalProperties.$http = request;
app.use(store).use(router).mount("#app");
