import axios from "axios";
import router from "./router";

// console.log('read axios...')
// axios.defaults.baseURL = "https://fms-service.azurewebsites.net/api/v1"
axios.defaults.baseURL = "https://fibromyalgia.pain.org.tw/api/v1"
// axios.defaults.baseURL = "http://127.0.0.1/api/v1"
const request = axios.create({
	timeout: 30000,
	headers: {
		'Content-Type': 'application/json; charset=utf-8'
	}
})

request.interceptors.request.use(config => {
	// console.log("ax interceptor req....");
	config.headers['Authorization'] = localStorage.getItem("token") // 请求头带上token
	// console.log(`token = ${localStorage.getItem('token')}`);
	return config
})

request.interceptors.response.use(response => {
		// console.log("ax interceptor resp....")
		let res = response.data;
		// console.log("ax response")
		// console.log(res)
		if (response.status === 200) {
			return response
		} else if(response.status === 400){
			return response
		}else {
			console.log(res.code);
			console.log(res.msg ? res.msg : '系統異常！')
			Element.Message.error(res.msg ? res.msg : '系統異常！', { duration: 3 * 1000 })
			return Promise.reject(response.data.msg)
		}
	},
	error => {
		if (error.response.status === 401) {
			// console.log('未登入');
			return;
			// router.push("/login")
		}
		console.log(error);
		Element.Message.error(res.msg ? res.msg : '系統連線異常！', { duration: 3 * 1000 })
		if (error.response.data) {
			error.message = error.response.data.msg
		}
		Element.Message.error(error.message, { duration: 3 * 1000 })
		return Promise.reject(error)
	}
)
export default request